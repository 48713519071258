import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Divider, Modal, Typography, Collapse, Stack } from "@mui/material";
import { createSupportNotification } from "../lib/usersBEClient";
import { Formik, Form, FormikProps, useFormikContext } from "formik";
import { supportNotificationSchema } from "../const/SchemaValidations";
import { InputTextField } from "./Formik Components/InputTextField";
import { InputSliderSelector } from "./Formik Components/InputSliderSelector";
import { SnackBarContext } from "../context/snackBarContext";
import { InputCheckBox } from "./Formik Components/InputCheckBox";
import { useParams } from "react-router-dom";
import { verifyReportNumber } from "../lib/lecosyBackendClient";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface HelpMessageModalProps {
   open: boolean;
   setOpen: Function;
   companyName: string;
}

export const HelpMessageModal = (props: HelpMessageModalProps) => {
   const { subName } = useParams();
   const { showSnackBar } = useContext(SnackBarContext);
   const [verifiedReportNumber, setVerifiedReportNumber] = useState<boolean>(false);
   const [reportNumber, setReportNumber] = useState<string>("");

   const validateReportNumber = async (reportNumber: string) => {
      const response = await verifyReportNumber(reportNumber);
      if (response) setReportNumber(reportNumber);
      setVerifiedReportNumber(response);

      return response ? null : showSnackBar("Número de reporte de denuncia inválido", true);
   };
   const handleSupportNotification = async (values) => {
      const response = await createSupportNotification(
         props.companyName,
         values.email,
         values.body,
         values.isCR,
         values.name,
         values.phoneNumber.toString(),
         values.reportNumber
      );
      if (response.data.message === "notification sent") {
         showSnackBar("Notificación enviada", false);
         handleCloseModal();
      } else showSnackBar("Intente de nuevo", true);
   };
   const handleCloseModal = () => {
      setVerifiedReportNumber(false);
      props.setOpen(false);
   };

   const FormObserver: React.FC = () => {
      const { values } = useFormikContext();
      useEffect(() => {
         const rp: any = values;
         if (rp.reportNumber !== reportNumber) setVerifiedReportNumber(false);
      }, [values]);
      return null;
   };

   return (
      <div>
         <Modal
            open={props.open}
            onClose={handleCloseModal}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
         >
            <Box display="flex" flexDirection="column" sx={{ bgcolor: "white", borderRadius: 2, maxWidth: "95%" }}>
               <Typography variant="h5" fontWeight={500} sx={{ p: 2 }} letterSpacing={1}>
                  Escríbenos tu duda
               </Typography>
               <Formik
                  initialValues={{
                     isCR: false,
                     reportNumber: "",
                     name: "",
                     email: "",
                     phoneNumber: "",
                     body: "",
                     checked: false,
                  }}
                  onSubmit={(values) => handleSupportNotification(values)}
                  validationSchema={supportNotificationSchema}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <FormObserver />
                        <Box sx={{ p: 2 }}>
                           <Box display="flex" alignItems="center" justifyContent="space-between">
                              <Typography>¿Tienes una duda sobre una denuncia ya creada?</Typography>
                              <InputSliderSelector id="isCR" name="isCR" />
                           </Box>
                           <Collapse in={formProps.values.isCR} timeout="auto" unmountOnExit>
                              <Box sx={{ py: 2 }}>
                                 <Typography sx={{ mb: 1 }}>Escribe el número de reporte de tu denuncia:</Typography>
                                 <Stack direction={"row"} display={"flex"} alignItems={"center"}>
                                    <InputTextField
                                       data-cy="reportNumberInput"
                                       fullWidth
                                       id="support-textfield"
                                       name="reportNumber"
                                       type="string"
                                       variant="outlined"
                                       size={"small"}
                                    />
                                    {verifiedReportNumber ? (
                                       <Box mx={1}>
                                          <CheckCircleIcon color="success" />
                                       </Box>
                                    ) : (
                                       <></>
                                    )}
                                 </Stack>
                              </Box>
                              <Button
                                 variant="contained"
                                 disabled={verifiedReportNumber}
                                 disableRipple={verifiedReportNumber}
                                 onClick={() => validateReportNumber(formProps.values.reportNumber)}
                                 sx={{ bgcolor: "#162c44", color: "white" }}
                              >
                                 <Typography>Verificar</Typography>
                              </Button>
                           </Collapse>
                           <Divider sx={{ my: 1 }} />
                           <Box
                              sx={{
                                 opacity: formProps.values.isCR && !verifiedReportNumber ? 0.5 : 1,
                                 pointerEvents: formProps.values.isCR && !verifiedReportNumber ? "none" : "auto",
                              }}
                           >
                              <Box sx={{ mb: 1 }}>
                                 <Typography variant="body1">Nombre</Typography>
                                 <InputTextField
                                    data-cy="nameInput"
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    id="support-textfield"
                                    name="name"
                                    type="text"
                                 />
                              </Box>
                              <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" sx={{ mb: 1 }}>
                                 <Box sx={{ flex: 1, mr: 0.5 }}>
                                    <Typography variant="body1">Correo electrónico *</Typography>
                                    <InputTextField
                                       data-cy="emailInput"
                                       fullWidth
                                       size="small"
                                       variant="outlined"
                                       id="support-textfield"
                                       name="email"
                                       type="email"
                                    />
                                 </Box>
                                 <Box sx={{ flex: 1, ml: 0.5 }}>
                                    <Typography variant="body1">Celular</Typography>
                                    <InputTextField
                                       data-cy="phoneNumberInput"
                                       fullWidth
                                       size="small"
                                       variant="outlined"
                                       id="support-textfield"
                                       name="phoneNumber"
                                       type="number"
                                    />
                                 </Box>
                              </Box>
                              <Box>
                                 <Typography variant="body1">¿Cuál es tu duda? *</Typography>
                                 <InputTextField
                                    data-cy="questionInput"
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    id="support-textfield"
                                    name="body"
                                    type="text"
                                    maxRows={4}
                                 />
                              </Box>
                              <InputCheckBox
                                 datacy={"privacyCheck"}
                                 id={"checked"}
                                 name={"checked"}
                                 label={
                                    <Typography sx={{ fontSize: { xs: "15px", md: "auto" } }}>
                                       He leído y acepto el&nbsp;{" "}
                                       <a
                                          href={`${subName}/Aviso-de-privacidad`}
                                          rel="noreferrer"
                                          target="_blank"
                                          style={{
                                             color: "#162c44",
                                             textDecoration: "none",
                                          }}
                                       >
                                          <strong>Aviso de privacidad</strong>
                                       </a>
                                    </Typography>
                                 }
                              />
                           </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                           <Button
                              variant="text"
                              sx={{
                                 flex: 1,
                                 p: 2,
                                 borderBottomLeftRadius: 8,
                                 color: "red",
                                 ":hover": {
                                    color: "red",
                                 },
                              }}
                              onClick={() => {
                                 props.setOpen(false);
                              }}
                           >
                              Cerrar
                           </Button>
                           <Button
                              data-cy="sendBtn"
                              variant="text"
                              type="submit"
                              sx={{ flex: 1, p: 2, borderBottomRightRadius: 8 }}
                           >
                              Enviar
                           </Button>
                        </Box>
                     </Form>
                  )}
               </Formik>
            </Box>
         </Modal>
      </div>
   );
};
