import React from "react";
import { Box } from "@mui/material";

type BgImageProps = {
   src: string;
   children: JSX.Element;
};

export const BgImage: React.FC<BgImageProps> = ({ src, children }) => {
   return (
      <Box
         sx={{
            display: "flex",
            alignItems: { xs: "flex-start", md: "center" },
            backgroundImage: `url(${src})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minHeight: "",
            // height: "100%",
            // height: "88vh",
            height: "88vh",
            // height: "80vh",
         }}
      >
         {children}
      </Box>
   );
};
