import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CompanyContext } from "../context/companyContext";
import { Box, Button, Stack, Typography } from "@mui/material";
import { HelpMessageModal } from "./HelpMessageModal";
import { bgContrast } from "../const/bgContrast";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const ComplaintAction = () => {
   const { company } = useContext(CompanyContext);
   const navigate = useNavigate();
   const [openSupportModal, setOpenSupportModal] = useState(false);

   const handleNewComplaintClick = (type) => {
      navigate(`nueva/${type}`);
   };

   return (
      <Box sx={{ minWidth: 300 }}>
         <Stack
            display={"flex"}
            justifyContent={"center"}
            sx={{ alignItems: { xs: "center", md: "center" }, py: { xs: "40px", md: "0px" } }}
            spacing={{ xs: 2, md: 4 }}
         >
            <Button
               onClick={() => handleNewComplaintClick("denuncia")}
               variant="contained"
               sx={{
                  display: "flex",
                  color: bgContrast(company.color),
                  bgcolor: `${company.color}`,
                  width: "220px",
                  borderRadius: "12px",
                  alignContent: "center",
                  justifyContent: "left",
               }}
            >
               <Typography fontSize={"18px"} fontWeight={600}>
                  Haz tu denuncia
               </Typography>
               <Box position={"relative"} left={30} top={5}>
                  <ArrowForwardIosIcon />
               </Box>
            </Button>
            <Button
               onClick={() => handleNewComplaintClick("sugerencia")}
               variant="contained"
               sx={{
                  display: "flex",
                  color: bgContrast(company.color),
                  bgcolor: `${company.color}`,
                  width: "220px",
                  borderRadius: "12px",
                  justifyContent: "left",
               }}
            >
               <Typography fontSize={"18px"} fontWeight={600}>
                  Brindar sugerencia
               </Typography>
               <Box position={"relative"} left={8} top={5}>
                  <ArrowForwardIosIcon />
               </Box>
            </Button>
            <Button
               onClick={() => handleNewComplaintClick("queja")}
               variant="contained"
               sx={{
                  display: "flex",
                  color: bgContrast(company.color),
                  bgcolor: `${company.color}`,
                  width: "220px",
                  borderRadius: "12px",
                  justifyContent: "left",
               }}
            >
               <Typography fontSize={"18px"} fontWeight={600}>
                  Presentar queja
               </Typography>
               <Box position={"relative"} left={35} top={5}>
                  <ArrowForwardIosIcon />
               </Box>
            </Button>
         </Stack>
         <Box display={"flex"} flexDirection="column" sx={{ bgcolor: "transparent", py: { md: "30px" } }}>
            <Typography variant="h6" sx={{ mb: 1 }} textAlign={"center"}>
               ¿Tiene alguna duda?
            </Typography>
            <Box display="flex" justifyContent={"center"}>
               <Button
                  daya-cy="questionBtn"
                  variant="outlined"
                  sx={{
                     borderColor: `${company.color}`,
                     "&:hover": { borderColor: `${company.color}`, bgcolor: "white" },
                  }}
                  onClick={() => {
                     setOpenSupportModal(true);
                  }}
               >
                  Enviar mensaje
               </Button>
            </Box>
         </Box>
         <HelpMessageModal open={openSupportModal} setOpen={setOpenSupportModal} companyName={company.id} />
      </Box>
   );
};
