import axios from "axios";

const base_url =
   window.location.hostname === "test.denuncias.lecosy.com.mx" ||
   window.location.hostname === "www.test.denuncias.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx"
      : "http://localhost:8002";

const host = base_url;
const BASE_ENDPOINT = "/api";
const COMPANIES_ENDPOINT = "/companies";
const CLIENT_ENDPOINT = "/client";
const BRANCHES_ENDPOINT = "/branches";
const SUPPORTNOTIF_ENDPOINT = "/sup-notif";
const PLATFORM_ENDPOINT = "/platform-info";
const S3_ENDPOINT = "/s3";

export const getPlatformInfo = async (subName: string) => {
   try {
      return await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${PLATFORM_ENDPOINT}/${subName}`);
   } catch (error) {
      console.log("error", error);
   }
};

export const getCompanyInfo = async (subName: string) => {
   try {
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPANIES_ENDPOINT}/${subName}`);
      return data;
   } catch (error) {
      console.log("error", error);
   }
};

export const getCompanyBranches = async (companyId: string) => {
   return axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/channel${BRANCHES_ENDPOINT}/company/${companyId}`);
};

export const createSupportNotification = async (
   company: string,
   email: string,
   body: string,
   isCR: boolean,
   name: string,
   phoneNumber?: string,
   reportNumber?: string
) => {
   const reqBody = {
      company: company,
      email: email,
      body: body,
      isCR: isCR,
      name: name,
      phoneNumber: phoneNumber,
      reportNumber: reportNumber,
   };
   return axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${SUPPORTNOTIF_ENDPOINT}/new`, reqBody);
};

export const getUrlForEvidenceUpload = async (evidences: string[], id: string, reportNumber: string) => {
   try {
      axios.defaults.withCredentials = true;
      const preSignedUrl: any[] = [];
      for (const fileKey of evidences) {
         const body = {
            folder: {
               folder: id
                  ? `evidences/${reportNumber}/Denunciado/${id}`
                  : `evidences/${reportNumber}/Denunciado/anonimo`,
            },
            name: fileKey,
         };
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/channel/upload`, body);
         preSignedUrl.push(data);
      }
      if (preSignedUrl.length === 0) throw new Error("document not uploaded");
      return preSignedUrl;
   } catch (error) {
      console.log("error", error);
   }
};

export const complaintUploadPdfEvidence = async (signedURL, file) => {
   const config = {
      headers: {
         "Content-Type": `${file.type}`,
      },
   };
   axios.defaults.withCredentials = false;
   return await axios.put(signedURL, file, config);
};

export const getUrlForS3File = async (folder: string, fileKey: string) => {
   //get logos only, uses images-lecosy bucket
   try {
      axios.defaults.withCredentials = true;
      const body = {
         folder: folder,
      };
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/channel/get/${fileKey}`, body);
      return data;
   } catch (error) {
      console.log(error);
   }
};
