import { useContext } from "react";
import { CompanyContext } from "../context/companyContext";
import { Box, Stack, Typography } from "@mui/material";
import { bgContrast } from "../const/bgContrast";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

export const CostumerInfo = () => {
   const { company } = useContext(CompanyContext);
   return (
      <Stack direction={"column"} spacing={3}>
         <Box
            sx={{
               backgroundColor: company.color,
               color: bgContrast(company.color),
               width: { xs: "100%", md: "95%" },
               borderTopRightRadius: { md: 90 },
               borderBottomRightRadius: { md: 40 },
               pr: { xs: 2, md: 10 },
               pl: { xs: 2, md: 10 },
               pt: { xs: 4, md: 14 },
               pb: { xs: 2, md: 8 },
               mt: { xs: "40px", md: 0 },
            }}
         >
            <Typography textAlign={"justify"}>{company.wt}</Typography>
         </Box>
         <Box px={{ md: 10 }}>
            <Typography variant="h6" textAlign={{ xs: "center", md: "left" }}>
               Medio de contacto telefónico
            </Typography>
            <Box
               display={"flex"}
               flexDirection={"row"}
               alignItems="center"
               marginTop={1}
               sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
            >
               <LocalPhoneIcon fontSize="small" sx={{ color: "gray" }} />
               <Typography sx={{ px: 2 }}>(662)151 69 43</Typography>
            </Box>
         </Box>
      </Stack>
   );
};
