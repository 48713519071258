import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../const/complaintsConst";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface StatusCheckModalProps {
   open: boolean;
   setOpen: Function;
   status: string;
   observations: string;
   company: {
      name: string;
      subName: string;
      color: string;
      logo: File;
   };
}

export const StatusCheckModal = (props: StatusCheckModalProps) => {
   const navigate = useNavigate();

   const getStatusColor = (status: string) => {
      if (status === "enviada") return "#828282";
      if (status === "improcedente") return "#ED1C1C";
      if (status === "en proceso") return "#F38A1A";
      if (status === "en valoracion") return "#F38A1A";
      if (status === "resuelto") return "#14E217";
      if (status === "cerrado") return "#2D4357";
   };
   return (
      <>
         <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
         >
            <Box flexDirection="column" sx={{ backgroundColor: "white", borderRadius: 4, minWidth: 400 }}>
               <Box display="flex" flexDirection="column">
                  <Box p={2}>
                     <Typography display="block" variant="h5" textAlign={"center"}>
                        Estatus de denuncia
                     </Typography>
                  </Box>
                  <Divider />
                  <Box p={2} display="flex" flexDirection="column" alignItems="center">
                     <Typography display="inline" variant="subtitle1">
                        Tu denuncia está en estatus:&nbsp;
                        {capitalizeFirstLetter(props.status)}
                     </Typography>
                     <Typography display="inline" variant="subtitle2"></Typography>
                     <CheckCircleOutlineIcon sx={{ fontSize: 35, mx: 2, mt: 1, color: getStatusColor(props.status) }} />
                  </Box>
               </Box>
               <Box
                  display="flex"
                  justifyContent="flex-end"
                  bgcolor="#F5F5F5"
                  sx={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}
               >
                  <Button
                     color="warning"
                     onClick={() => {
                        props.setOpen(false);
                     }}
                     sx={{ flex: 1, p: 2, borderBottomLeftRadius: 16 }}
                  >
                     Cerrar
                  </Button>
                  <Button
                     color="success"
                     onClick={() => {
                        props.setOpen(false);
                        navigate(`/${props.company.subName}`);
                     }}
                     sx={{ flex: 1, p: 2, borderBottomRightRadius: 16 }}
                  >
                     Aceptar
                  </Button>
               </Box>
            </Box>
         </Modal>
      </>
   );
};
