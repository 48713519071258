import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";

type ThemeProps = {
   children: JSX.Element | JSX.Element[];
};

const theme = createTheme({
   palette: {
      mode: "light",
      primary: {
         main: "#D6D6D6",
      },
   },
   components: {
      MuiButton: {
         styleOverrides: {
            root: {
               color: "black",
               borderColor: "#D8D8D8",
               "&:hover": {
                  backgroundColor: "#E9E9E9",
                  color: "black",
                  borderColor: "#EAEAEA",
               },
            },
         },
      },
   },
   typography: {
      button: {
         textTransform: "none",
      },
      fontFamily: "Regular",
   },
});

export const SystemThemeConfig: React.FC<ThemeProps> = ({ children }) => {
   return (
      <ThemeProvider theme={theme}>
         <CssBaseline />
         {children}
      </ThemeProvider>
   );
};
