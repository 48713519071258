// @mui imports
import {
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   Collapse,
   Container,
   Divider,
   FormLabel,
   ListItemText,
   MenuItem,
   Radio,
   RadioGroup,
   Typography,
   IconButton,
   FormControl,
   CssBaseline,
   FormControlLabel,
   Tooltip,
   Paper,
   Stack,
} from "@mui/material";
// @mui icons imports
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
// imports
import { useContext, useEffect, useState, useRef, useMemo } from "react";
import { categoryOptions, complainerTypeOptions } from "../const/complaintsConst";
import { CompanyContext } from "../context/companyContext";
import { NavBar } from "../components/NavBar";
import { ThankYouModal } from "../components/ThankYouModal";
import { ComplainerDetailsModal } from "../components/ComplainerDetailsModal";
import { NewComplaintBar } from "../components/NewComplaintBar";
import { createComplaint } from "../lib/lecosyBackendClient";
import { useParams } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { WitnessInfractorModal } from "../components/WitnessInfractorModal";
import { Formik, Form, FormikProps } from "formik";
import { InputTextField } from "../components/Formik Components/InputTextField";
import { InputSelectField } from "../components/Formik Components/InputSelectField";
import { complaintFormSchema, niggleFormSchema, sugestionFormSchema } from "../const/SchemaValidations";
import { SnackBarContext } from "../context/snackBarContext";
import { SnackBarComponent } from "../components/snackBarComponent";
import Compressor from "compressorjs";
import { complaintUploadPdfEvidence, getUrlForEvidenceUpload } from "../lib/usersBEClient";
import { InputCheckBox } from "../components/Formik Components/InputCheckBox";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const NewComplaintScreen = () => {
   const { getInfo, company } = useContext(CompanyContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { subName, type } = useParams();
   const evidenceRef: any = useRef(null);
   // ComplainerDetails form for non anonymous
   const [nonAnonymousFormVisible, setNonAnonymousFormVisible] = useState(false);
   const [offenderFormOpen, setOffenderFormOpen] = useState(false);
   const [witnessFormOpen, setWitnessFormOpen] = useState(false);
   const [thankyouModalVisible, setThankyouModalVisible] = useState(false);
   const [complaintResultInfo, setComplaintResultInfo] = useState({
      rn: "",
      vc: "",
   });
   const [hasEvidence, setHasEvidence] = useState(false);
   const [evidenceBlob, setEvidenceBlob] = useState([]);
   const [evidences, setEvidences] = useState([]);
   const [isSendingComplaint, setIsSendingComplaint] = useState(false);

   const validationSchema = useMemo(() => {
      if (type === "denuncia") return complaintFormSchema;
      if (type === "queja") return niggleFormSchema;
      return sugestionFormSchema;
   }, [type]);

   const descriptionHolder = useMemo(() => {
      if (type === "denuncia")
         return "Indicar al mayor detalle posible qué, cómo, cuándo y dónde sucedió el incidente, quiénes están dos (nombre y puesto), así como lo que obtienen. Se debe incluir cualquier información que pueda ser valiosa en la evaluación y resolución de esta situación.";
      if (type === "sugerencia")
         return "Tu opinión es importante para nosotros. Describa su sugerencia con claridad y precisión, incluyendo cualquier detalle que considere relevante.";
      return "Proporcione una descripción detallada de su queja, incluyendo todos los eventos relevantes, fechas y cualquier otra información pertinente que nos ayude a entender completamente su situación.";
   }, [type]);
   useEffect(() => {
      getInfo(subName);
      // eslint-disable-next-line
   }, []);

   const onEvidenceUpload = async (e) => {
      try {
         if (evidenceBlob.length < 3) {
            if (e.target.files.length === 0) return;
            if (e.target.files[0].type.startsWith("image")) {
               const compressor = new Compressor(e.target.files[0] as Blob, {
                  quality: 0.75,
                  success(result: File) {
                     if (result.type !== "video/mp4" && result.size > 10000000) {
                        showSnackBar("Archivo mayor a 10 MB", true);
                        return;
                     }
                     setEvidenceBlob([...evidenceBlob, result]);
                     setHasEvidence(true);
                     setEvidences([...evidences, result.name]);
                     evidenceRef.current.value = null;
                  },
                  error(err) {
                     console.log({ err });
                     showSnackBar("Error, intente de nuevo más tarde", true);
                     return compressor.abort();
                  },
               });
               return;
            } else {
               if (e.target.files[0].type.startsWith("video") && e.target.files[0].size > 50000000) {
                  showSnackBar("Archivo mayor a 50 MB", true);
                  return;
               }
               if (e.target.files[0].type.includes("pdf") && e.target.files[0].size > 5000000) {
                  showSnackBar("Archivo mayor a 5 MB", true);
                  return;
               }
               setEvidenceBlob([...evidenceBlob, e.target.files[0]]);
               setHasEvidence(true);
               setEvidences([...evidences, e.target.files[0].name]);
               evidenceRef.current.value = null;
               return;
            }
         }
         showSnackBar("Límite de 3 archivos alcanzado.", true);
      } catch (error) {
         showSnackBar("Error, intente de nuevo.", true);
      }
   };

   async function handleNewComplaintFormik(values, actions) {
      try {
         let selectedCompany = null;
         if (values.branch === "") selectedCompany = company.id;
         setIsSendingComplaint(true);
         const complaintRepsonse = await createComplaint(
            type,
            company.subName,
            company.intern,
            values.followUpEmail,
            values.complaintType,
            values.complainerType,
            values.body,
            values.category,
            values.date,
            values.specificPlace,
            values.present,
            values.directlyAffected,
            selectedCompany,
            values.branch,
            values.pseudonym,
            values.complainerDetails,
            values.witnesses.length > 0 ? values.witnesses : undefined,
            evidences.length > 0 ? evidences : undefined,
            values.offenderDetails.length > 0 ? values.offenderDetails : undefined
         );
         if (complaintRepsonse.data.message === "Branch does not exists") {
            showSnackBar("Esta empresa no cuenta con sucursales aún.", true);
            return;
         }
         if (complaintRepsonse.status !== 201) {
            showSnackBar("Error, intente de nuevo", true);
            return;
         }
         const complaitResponseObtained = complaintRepsonse.data;
         if (evidenceBlob.length > 0) {
            const complaintData = await getUrlForEvidenceUpload(
               evidences,
               complaitResponseObtained.complainerDetails ? complaitResponseObtained.complainerDetails._id : undefined,
               complaitResponseObtained.reportNumber
            );
            for (let i = 0; i < evidenceBlob.length; i++) {
               await complaintUploadPdfEvidence(complaintData[i], evidenceBlob[i]);
            }
         }
         showSnackBar("Denuncia enviada con éxito", false);
         setComplaintResultInfo({
            ...complaintResultInfo,
            rn: complaitResponseObtained.reportNumber,
            vc: complaitResponseObtained.verificationCode,
         });
         setThankyouModalVisible(true);
         setIsSendingComplaint(false);
         actions.resetForm();
      } catch (error) {
         showSnackBar("Ocurrió un error al procesar la información, favor de reintentar.", true);
         setIsSendingComplaint(false);
         console.log(error);
      }
   }

   const openManual = () => {
      window.open("https://images-lecosy.s3.us-west-2.amazonaws.com/manuals/manual-Interposicion.pdf", "_blank");
   };

   return (
      <>
         <NavBar />
         <Formik
            initialValues={{
               complainerDetails: "",
               pseudonym: "",
               followUpEmail: "",
               complaintType: "anonima",
               branch: "",
               complainerType: "",
               category: "",
               date: "",
               body: "",
               witnesses: [],
               specificPlace: "",
               directlyAffected: null,
               present: null,
               offenderDetails: [],
               checked: false,
            }}
            onSubmit={handleNewComplaintFormik}
            validationSchema={validationSchema}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Container maxWidth={"md"} sx={{ backgroundColor: "white", boxShadow: 3, pt: 1 }}>
                     <CssBaseline />
                     <Box display={"flex"} justifyContent={"space-between"} flexDirection={{ xs: "column", md: "row" }}>
                        <NewComplaintBar text={`Información acerca de su ${type}`} />
                        {type === "denuncia" && (
                           <Button
                              variant="text"
                              size="small"
                              endIcon={<HelpIcon sx={{ color: "gray" }} />}
                              onClick={openManual}
                           >
                              ¿Cómo hago mi denuncia?
                           </Button>
                        )}
                     </Box>
                     <Paper elevation={0} sx={{ p: 1, borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                        {type !== "queja" && (
                           <div>
                              <Box sx={fieldSx}>
                                 <Typography fontWeight={"bold"} variant="body1" id="category-typography">
                                    Categoría
                                 </Typography>
                                 <InputSelectField id="category-select" name="category" variant="standard">
                                    {type === "denuncia"
                                       ? categoryOptions.map((e) => (
                                            <MenuItem key={e} value={e}>
                                               <ListItemText primary={e} />
                                            </MenuItem>
                                         ))
                                       : company.suggestions
                                            .map((suggestion) => (
                                               <MenuItem key={suggestion} value={suggestion}>
                                                  <ListItemText primary={suggestion} />
                                               </MenuItem>
                                            ))
                                            .concat(
                                               <MenuItem key={"Otro"} value={"Otro"}>
                                                  <ListItemText primary={"Otro"} />
                                               </MenuItem>
                                            )}
                                 </InputSelectField>
                              </Box>
                              <Divider sx={{ my: 3 }} />
                           </div>
                        )}
                        {/* /// holissssss juan :)  */}

                        <Box sx={fieldSx}>
                           <Typography fontWeight={"bold"} variant="body1" id="complainerType-typography">
                              {formProps.values.complainerDetails === ""
                                 ? `Hacer mi ${type} no anónima`
                                 : "Volver a denuncia anónima"}
                           </Typography>
                           <Button
                              variant="outlined"
                              data-cy="complainterBtn"
                              onClick={() => {
                                 if (formProps.values.complaintType === "anonima") {
                                    setNonAnonymousFormVisible(true);
                                 } else {
                                    formProps.setValues({
                                       ...formProps.values,
                                       complaintType: "anonima",
                                       complainerDetails: "",
                                       pseudonym: "",
                                       followUpEmail: "",
                                    });
                                 }
                              }}
                           >
                              {formProps.values.complainerDetails === ""
                                 ? "Agregar mi información"
                                 : "Quitar mi información"}
                           </Button>
                        </Box>
                        <Collapse in={formProps.values.complainerDetails !== ""}>
                           <Box sx={{ backgroundColor: "#F1F1F190", p: 1, borderRadius: 2 }}>
                              <Typography px={1}>
                                 Mi información: {formProps.values.complainerDetails.name}&nbsp;
                                 {formProps.values.complainerDetails.lastName}
                              </Typography>
                           </Box>
                        </Collapse>
                        <Box sx={fieldSx}>
                           <Typography fontWeight={"bold"} variant="body1" id="pseudonym-typography">
                              Pseudónimo
                           </Typography>
                           <InputTextField
                              disabled={formProps.values.complaintType === "no anonima"}
                              type="text"
                              id="pseudonym"
                              name="pseudonym"
                              fullWidth={false}
                              variant="standard"
                              value={formProps.values.pseudonym}
                           />
                        </Box>
                        <Box sx={fieldSx}>
                           <Typography fontWeight={"bold"} variant="body1" id="followUpEmail-label">
                              Correo de seguimiento
                           </Typography>
                           <InputTextField
                              disabled={formProps.values.complaintType === "no anonima"}
                              type="text"
                              id="followUpEmail"
                              name="followUpEmail"
                              fullWidth={false}
                              variant="standard"
                              value={formProps.values.followUpEmail}
                              endAdornment={
                                 <Tooltip title="Este correo se utiliza solamente para enviarte notificaciones y darte el correcto seguimiento.">
                                    <InfoIcon color="action" />
                                 </Tooltip>
                              }
                           />
                        </Box>
                        <Divider variant="middle" flexItem sx={{ mt: 2, mb: 1 }} />
                        {type !== "queja" && (
                           <Box sx={fieldSx}>
                              {
                                 <div>
                                    <Typography fontWeight={"bold"} variant="body1" id="complainerType-typography">
                                       Sucursal/establecimiento
                                    </Typography>
                                 </div>
                              }
                              <div>
                                 <InputSelectField id="branch-select" name="branch" variant="standard">
                                    {company.branches.map((e) => (
                                       <MenuItem key={e.id} value={e.id}>
                                          <ListItemText primary={e.name} />
                                       </MenuItem>
                                    ))}
                                 </InputSelectField>
                              </div>
                           </Box>
                        )}
                        <Box sx={fieldSx}>
                           <div>
                              <Typography fontWeight={"bold"} variant="body1" id="complainerType-typography">
                                 {type === "denuncia"
                                    ? "¿Qué relación tiene con la organización denunciada?"
                                    : "¿Qué relación tiene con la organización?"}
                              </Typography>
                           </div>
                           <div>
                              <InputSelectField id="complainerType-select" name="complainerType" variant="standard">
                                 {complainerTypeOptions.map((e) => (
                                    <MenuItem key={e} value={e}>
                                       <ListItemText primary={e} />
                                    </MenuItem>
                                 ))}
                              </InputSelectField>
                           </div>
                        </Box>
                        {type === "denuncia" && (
                           <Box sx={fieldSx}>
                              <Typography fontWeight={"bold"} variant="body1" id="date-typography">
                                 Fecha del incidente
                              </Typography>
                              <InputTextField
                                 type="date"
                                 id="date"
                                 name="date"
                                 fullWidth={false}
                                 variant="standard"
                                 value={formProps.values.date}
                              />
                           </Box>
                        )}
                        {type !== "queja" && <Divider flexItem sx={{ my: 3 }} />}
                        <Box display="flex" flexDirection="column" m={1}>
                           <Typography fontWeight={"bold"} variant="body1" id="body-typography">
                              {`Describa aquí su ${type}`}
                           </Typography>
                           <InputTextField
                              placeholder={descriptionHolder}
                              multiline
                              type="text"
                              id="body"
                              name="body"
                              fullWidth={false}
                              variant="outlined"
                              value={formProps.values.body}
                              maxLength={2000}
                           />
                        </Box>
                        {type === "denuncia" && (
                           <div>
                              <Box sx={fieldSx}>
                                 <Typography fontWeight={"bold"}>Testigos o personas que conocen el caso</Typography>
                                 <Button
                                    variant="outlined"
                                    data-cy="addWitnessBtn"
                                    onClick={() => {
                                       if (formProps.values.witnesses.length < 2) {
                                          setWitnessFormOpen(true);
                                          return;
                                       }
                                       showSnackBar("Límite de 2 testigos alcanzado.", true);
                                    }}
                                 >
                                    Agregar Testigo
                                 </Button>
                              </Box>
                              <Box sx={fieldSx}>
                                 <ShowAddedInvolved
                                    array={formProps.values.witnesses}
                                    formProps={formProps}
                                    isOffender={false}
                                 />
                              </Box>
                              <Box sx={fieldSx}>
                                 <Typography fontWeight={"bold"} variant="body1" id="specificPlace-typography">
                                    Lugar en donde sucedieron los actos
                                 </Typography>
                                 <InputTextField
                                    type="text"
                                    id="specificPlace"
                                    name="specificPlace"
                                    fullWidth={false}
                                    variant="standard"
                                    value={formProps.values.specificPlace}
                                 />
                              </Box>
                           </div>
                        )}
                        {type !== "sugerencia" && (
                           <div>
                              <Divider variant="middle" flexItem sx={{ mt: 2, mb: 1 }} />
                              <Box sx={fieldSx}>
                                 <Typography
                                    sx={{ mr: 2 }}
                                    fontWeight={"bold"}
                                    variant="body1"
                                    id="specificPlace-label"
                                 >
                                    {type === "queja" ? "Adjuntar archivos" : "Evidencias"}
                                 </Typography>
                                 <input
                                    ref={evidenceRef}
                                    type="file"
                                    name="evidence"
                                    accept="image/png, image/jpeg, .pdf ,.mp3, audio/*, video/mp4, video/x-m4v, video/*"
                                    style={{
                                       borderRadius: 8,
                                       padding: 8,
                                       marginRight: 8,
                                       width: "80%",
                                       backgroundColor: "#F1F1F1",
                                    }}
                                    onChange={onEvidenceUpload}
                                 />
                              </Box>
                              <Collapse in={hasEvidence}>
                                 <Box sx={{ p: 1 }}>
                                    {evidenceBlob.map((file, index) => (
                                       <Card
                                          key={file.name}
                                          sx={{
                                             display: "flex",
                                             bgcolor: "#F9F9F9",
                                             flexDirection: "row",
                                             justifyContent: "space-between",
                                             mt: 1,
                                             boxShadow: 0,
                                          }}
                                       >
                                          <CardContent>
                                             <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>
                                                {file.name}
                                             </Typography>
                                          </CardContent>
                                          <CardActions>
                                             <IconButton
                                                onClick={() => {
                                                   setEvidenceBlob((prev) => prev.filter((item, i) => i !== index));
                                                   setEvidences((prev) => prev.filter((item, i) => i !== index));
                                                }}
                                             >
                                                <DeleteIcon sx={{ color: "red" }} />
                                             </IconButton>
                                          </CardActions>
                                       </Card>
                                    ))}
                                 </Box>
                              </Collapse>
                           </div>
                        )}
                        {type === "denuncia" && (
                           <div>
                              <Divider variant="middle" flexItem sx={{ mt: 2, mb: 1 }} />
                              <FormControl
                                 sx={radioFieldSx}
                                 error={
                                    formProps.touched.directlyAffected && Boolean(formProps.errors.directlyAffected)
                                 }
                                 variant="standard"
                              >
                                 <FormLabel required style={formLabelStyle} id="directlyAffected-radios">
                                    ¿Se le afectó directamente?
                                 </FormLabel>
                                 <Box>
                                    <RadioGroup
                                       row
                                       aria-labelledby="directlyAffected-radios"
                                       data-cy="directlyAffectedRadio"
                                       name="directlyAffected"
                                       value={formProps.values.directlyAffected}
                                       onChange={formProps.handleChange}
                                    >
                                       <FormControlLabel value={true} control={<Radio />} label="Si" />
                                       <FormControlLabel value={false} control={<Radio />} label="No" />
                                    </RadioGroup>
                                 </Box>
                              </FormControl>
                              <FormControl
                                 sx={radioFieldSx}
                                 error={formProps.touched.present && Boolean(formProps.errors.present)}
                                 variant="standard"
                              >
                                 <FormLabel required style={formLabelStyle} id="present-radios">
                                    ¿Estaba presente mientras ocurrió el incidente?
                                 </FormLabel>
                                 <Box>
                                    <RadioGroup
                                       row
                                       aria-labelledby="present-radios"
                                       data-cy="presentRadio"
                                       name="present"
                                       value={formProps.values.present}
                                       onChange={formProps.handleChange}
                                    >
                                       <FormControlLabel value={true} control={<Radio />} label="Si" />
                                       <FormControlLabel value={false} control={<Radio />} label="No" />
                                    </RadioGroup>
                                 </Box>
                              </FormControl>
                              <Divider variant="middle" flexItem sx={{ mt: 2, mb: 1 }} />
                              <Box sx={fieldSx}>
                                 <Typography fontWeight={"bold"} variant="body1" id="verificationCode-label">
                                    Infractor
                                 </Typography>
                                 <Button
                                    variant="outlined"
                                    data-cy="addOffenderBtn"
                                    onClick={() => {
                                       if (formProps.values.offenderDetails.length < 2) {
                                          setOffenderFormOpen(true);
                                          return;
                                       }
                                       showSnackBar("Límite de 2 infractores alcanzado.", true);
                                    }}
                                 >
                                    Agregar Infractor
                                 </Button>
                              </Box>
                              <Box sx={fieldSx}>
                                 <ShowAddedInvolved
                                    array={formProps.values.offenderDetails}
                                    formProps={formProps}
                                    isOffender
                                 />
                              </Box>
                           </div>
                        )}
                     </Paper>
                     <ComplainerDetailsModal
                        open={nonAnonymousFormVisible}
                        setOpen={setNonAnonymousFormVisible}
                        formProps={formProps}
                     />
                     <WitnessInfractorModal
                        isOffender={true}
                        title={"Agregar Infractor"}
                        open={offenderFormOpen}
                        setOpen={setOffenderFormOpen}
                        onConfirm={() => {}}
                        formProps={formProps}
                     />
                     <WitnessInfractorModal
                        isOffender={false}
                        title={"Agregar Testigo"}
                        open={witnessFormOpen}
                        setOpen={setWitnessFormOpen}
                        onConfirm={() => {}}
                        formProps={formProps}
                     />
                     <ThankYouModal
                        open={thankyouModalVisible}
                        setOpen={setThankyouModalVisible}
                        company={company}
                        reportNumber={complaintResultInfo.rn}
                        verificationCode={complaintResultInfo.vc}
                        type={type}
                     />
                     <SnackBarComponent />
                  </Container>
                  <Container maxWidth={"md"}>
                     <Stack direction={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <InputCheckBox
                           datacy={"privacyCheck"}
                           id={"checked"}
                           name={"checked"}
                           label={
                              <Typography sx={{ fontSize: { xs: "15px", md: "auto" } }}>
                                 He leído y acepto el&nbsp;{" "}
                                 <a
                                    href={`../Aviso-de-privacidad`}
                                    target="_blank"
                                    style={{
                                       color: "#162c44",
                                       textDecoration: "none",
                                    }}
                                 >
                                    <strong>Aviso de privacidad</strong>
                                 </a>
                              </Typography>
                           }
                        />
                        <Box my={2}>
                           <Button
                              data-cy="sendBtn"
                              type="submit"
                              variant="outlined"
                              sx={{ bgcolor: company.color, color: "white", fontSize: "16px" }}
                           >
                              {!isSendingComplaint ? "Enviar" : <CircularProgress size={24} color="success" />}
                           </Button>
                        </Box>
                     </Stack>
                  </Container>
               </Form>
            )}
         </Formik>
      </>
   );
};

interface ShowAddedInvolvedProps {
   formProps: FormikProps<any>;
   array: any[];
   isOffender: boolean;
}

const ShowAddedInvolved = (props: ShowAddedInvolvedProps) => {
   return (
      <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
         {props.array.map((o, i) => (
            <Box
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               key={`o${i}`}
               sx={{ bgcolor: "#F1F1F1", m: 0.5, p: 1, borderRadius: 1 }}
            >
               <Box sx={{ flex: 1, pr: 1 }}>
                  <Box display="flex">
                     <Box mr={2}>
                        {o.name !== "" ? (
                           <>
                              Nombre: {o.name} {o.lastName}
                           </>
                        ) : props.isOffender ? (
                           <>Infractor {i + 1}</>
                        ) : (
                           <>Testigo {i + 1}</>
                        )}
                     </Box>
                     <Box>Género: {o.gender}</Box>
                  </Box>
                  {o.observations ? <Box>Observaciones: {o.observations}</Box> : <></>}
               </Box>
               <Box display="flex" justifyContent="space-between">
                  <IconButton
                     color="warning"
                     component="label"
                     sx={{ ml: 1 }}
                     onClick={() => {
                        props.formProps.setFieldValue(
                           props.isOffender ? "offenderDetails" : "witnesses",
                           props.isOffender
                              ? props.formProps.values.offenderDetails.filter(
                                   (item) => item !== props.formProps.values.offenderDetails[i]
                                )
                              : props.formProps.values.witnesses.filter(
                                   (item) => item !== props.formProps.values.witnesses[i]
                                )
                        );
                     }}
                  >
                     <ClearIcon />
                  </IconButton>
               </Box>
            </Box>
         ))}
      </Box>
   );
};

const fieldSx = {
   display: "flex",
   alignItems: "center",
   justifyContent: "space-between",
   m: 1,
};

const radioFieldSx = {
   display: "flex",
   flexDirection: "row",
   justifyContent: "space-between",
   alignItems: "center",
   m: 1,
};

const formLabelStyle = {
   flex: 1,
   color: "black",
   fontWeight: 600,
   animation: "none",
};
