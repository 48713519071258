import { createContext, useState } from "react";
import { getCompanyBranches, getPlatformInfo, getUrlForS3File } from "../lib/usersBEClient";

type ICompanyContext = {
   company: {
      id: string;
      name: string;
      subName: string;
      color: string;
      logo: File;
      intern: boolean;
      wt: string;
      branches: any[];
      suggestions: string[];
   };
   getInfo: (subName: string) => void;
   notFound: boolean;
};

export const CompanyContext = createContext<ICompanyContext>({
   company: {
      id: "",
      name: "",
      subName: "",
      color: "",
      intern: false,
      logo: undefined,
      wt: "",
      branches: [],
      suggestions: [],
   },
   getInfo: () => {},
   notFound: false,
});

export const CompanyProvider = ({ children }) => {
   const [notFound, setNotFound] = useState(false);
   const [company, setCompany] = useState({
      id: "",
      name: "",
      color: "",
      subName: "",
      logo: undefined,
      intern: false,
      wt: "",
      branches: [],
      suggestions: [],
   });

   const getCompanyLogo = async (clientId: string) => {
      try {
         const logoUrl: string = await getUrlForS3File(clientId, "logo.png");
         const companyLogo = await fetch(logoUrl).then(async (data) => {
            const newBlob = await data.blob();
            const file = new File([newBlob], "logo.png", { type: "image/png" });
            return file;
         });
         return companyLogo;
      } catch (error) {}
   };

   const getInfo = async (subName: string) => {
      try {
         const PlatformResponse = await getPlatformInfo(subName);
         const PlatformInfo = PlatformResponse.data;
         //if (PlatformInfo.disabled) throw new AxiosError("disabled");
         const branches = await getCompanyBranches(PlatformInfo.companyInfo._id);
         const branchesInfo = branches.data;
         const branchesName: any[] = [];
         for (const branch of branchesInfo) {
            branchesName.push({ name: branch.name, id: branch._id });
         }
         const companyLogo = await getCompanyLogo(PlatformInfo.companyInfo._id);
         setCompany({
            ...company,
            id: PlatformInfo.companyInfo._id,
            color: PlatformInfo.companyInfo.company_details.primaryColor || PlatformInfo.platformInfo.color,
            wt: PlatformInfo.platformInfo.message,
            intern: PlatformInfo.platformInfo.intern,
            name: PlatformInfo.companyInfo.person_details.comercialName,
            subName: PlatformInfo.platformInfo.subName,
            logo: companyLogo,
            branches: branchesName,
            suggestions: PlatformInfo.platformInfo.suggestions || [],
         });
      } catch (err) {
         setNotFound(true);
      }
   };

   return <CompanyContext.Provider value={{ company, getInfo, notFound }}>{children}</CompanyContext.Provider>;
};
