import { ComplaintInvolvedType } from "../types/BaseTypes";
import { Box, Button, MenuItem, Modal, Typography, Grid, IconButton, Tooltip } from "@mui/material";
import { Formik, Form, FormikProps } from "formik";
import { witnessInfractorSchema } from "../const/SchemaValidations";
import { InputTextField } from "./Formik Components/InputTextField";
import { InputSelectField } from "./Formik Components/InputSelectField";
import HelpIcon from "@mui/icons-material/Help";

interface WitnessInfractorModalProps {
   isOffender: boolean;
   open: boolean;
   setOpen: Function;
   onConfirm: Function;
   involvedDetails?: ComplaintInvolvedType[];
   setInvolvedDetails?: Function;
   title: string;
   formProps: FormikProps<any>;
}

export const WitnessInfractorModal = (props: WitnessInfractorModalProps) => {
   const handleSubmit = async (values, actions) => {
      try {
         if (props.isOffender) {
            props.formProps.setValues({
               ...props.formProps.values,
               offenderDetails: [
                  ...props.formProps.values.offenderDetails,
                  {
                     name: values.name,
                     lastName: values.lastName,
                     gender: values.gender,
                     position: values.position,
                     observations: values.observations,
                  },
               ],
            });
         } else {
            props.formProps.setValues({
               ...props.formProps.values,
               witnesses: [
                  ...props.formProps.values.witnesses,
                  {
                     name: values.name,
                     lastName: values.lastName,
                     gender: values.gender,
                     position: values.position,
                     observations: values.observations,
                  },
               ],
            });
         }
         actions.resetForm();
         props.setOpen(false);
      } catch (err) {
         console.log("values", err);
      }
   };

   return (
      <Modal
         open={props.open}
         onClose={() => props.setOpen(false)}
         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
         <Box borderRadius={4} bgcolor="white" sx={{ maxWidth: { xs: "95%", md: 700 } }}>
            <Box
               pt={2}
               sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "left" },
                  px: { md: 4 },
                  alignItems: "center",
               }}
            >
               <Typography variant="h5" fontWeight={600} letterSpacing={1}>
                  {props.title}
               </Typography>
               <Box sx={{ px: 1 }}>
                  <Tooltip
                     title={
                        <Typography variant="body2">
                           Para completar la información, por favor agrega los campos de "Nombre/s" y "Apellido/s". Si
                           no los conoces, simplemente llena los campos de "Género" y "Descripción" para indicar las
                           características físicas.
                        </Typography>
                     }
                     placement="right"
                  >
                     <IconButton>
                        <HelpIcon />
                     </IconButton>
                  </Tooltip>
               </Box>
            </Box>
            <Formik
               initialValues={{
                  name: "",
                  lastName: "",
                  gender: "",
                  position: "",
                  observations: "",
               }}
               validationSchema={witnessInfractorSchema}
               onSubmit={handleSubmit}
            >
               {(formProps: FormikProps<any>) => (
                  <Form>
                     <Box p={2}>
                        <Grid container spacing={1}>
                           <Grid item xs={12} md={6}>
                              <InputTextField
                                 fullWidth
                                 label={props.isOffender ? "Nombre/s o Razón social" : "Nombre/s"}
                                 id="name"
                                 name="name"
                                 type="text"
                                 variant="standard"
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <InputTextField
                                 fullWidth
                                 variant="standard"
                                 label="Apellido/s"
                                 id="lastName"
                                 name="lastName"
                                 type="text"
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <InputSelectField fullWidth variant="standard" label="Género" id="gender" name="gender">
                                 <MenuItem value={"masculino"}>Masculino</MenuItem>
                                 <MenuItem value={"femenino"}>Femenino</MenuItem>
                                 <MenuItem value={"indefinido"}>Indefinido</MenuItem>
                              </InputSelectField>
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <InputTextField
                                 fullWidth
                                 variant="standard"
                                 label="Puesto"
                                 id="position"
                                 name="position"
                                 type="text"
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <InputTextField
                                 fullWidth
                                 multiline
                                 name="observations"
                                 variant="standard"
                                 label="Descripción"
                                 id="observations"
                                 type="text"
                                 maxLength={500}
                                 placeholder={`Incluye en este campo una breve descripción de tal ${
                                    props.isOffender ? "infractor o de la empresa" : "testigo"
                                 }.`}
                                 helperText={
                                    formProps.values.observations.length === 0
                                       ? "En caso de no contar con el nombre del infractor, haz una breve descripción de sus características o señas descriptivas"
                                       : null
                                 }
                              />
                           </Grid>
                        </Grid>
                     </Box>
                     <Box
                        display="flex"
                        justifyContent="space-between"
                        bgcolor={"#F1F1F1"}
                        sx={{
                           borderBottomLeftRadius: 16,
                           borderBottomRightRadius: 16,
                        }}
                     >
                        <Button
                           variant="text"
                           onClick={() => props.setOpen(false)}
                           sx={{ color: "#d32f2f", flex: 1, py: 2, borderBottomLeftRadius: 16 }}
                        >
                           Cerrar
                        </Button>
                        <Button
                           data-cy="saveBtn"
                           variant="text"
                           sx={{ flex: 2, py: 2, borderBottomRightRadius: 16 }}
                           type="submit"
                        >
                           Guardar
                        </Button>
                     </Box>
                  </Form>
               )}
            </Formik>
         </Box>
      </Modal>
   );
};
