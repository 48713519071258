import { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { ComplaintAction } from "../components/ComplaintAction";
import { CostumerInfo } from "../components/CustomerInfo";
import { NavBar } from "../components/NavBar";
import { CompanyContext } from "../context/companyContext";
import { useParams, useNavigate } from "react-router-dom";
import { BgImage } from "../components/BgImage";
import { SnackBarComponent } from "../components/snackBarComponent";

export const MainScreen = () => {
   const { getInfo, notFound } = useContext(CompanyContext);
   const navigate = useNavigate();
   const subName = useParams();

   useEffect(() => {
      getInfo(subName.subName);
      // eslint-disable-next-line
   }, []);
   return (
      <>
         {notFound ? (
            navigate("/404")
         ) : (
            <>
               <NavBar />
               <BgImage src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fondo-canalDeDenuncias-inicio.jpg">
                  <>
                     <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }}>
                        <Box display={"flex"} sx={{ flex: 0.5, alignItems: "center" }}>
                           <CostumerInfo />
                        </Box>
                        <Box display={"flex"} sx={{ flex: 0.5, alignItems: "center", justifyContent: "center" }}>
                           <ComplaintAction />
                        </Box>
                     </Box>
                     <Box
                        display={{ xs: "none", md: "block" }}
                        width={{ xs: "60%", md: "45%" }}
                        height={{ xs: "50px", md: "70px" }}
                        bgcolor={"#E4E4E7"}
                        position={"absolute"}
                        bottom={0}
                        right={0}
                        sx={{ borderTopLeftRadius: "60px" }}
                     />
                  </>
               </BgImage>
               <SnackBarComponent />
            </>
         )}
      </>
   );
};
