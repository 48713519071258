import { Box, Container } from "@mui/material";

export const CenteredLecosyScreen = () => {
   return (
      <Container
         maxWidth={"md"}
         sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
         }}
      >
         <Box
            component={"img"}
            src={"https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png"}
            sx={{ height: "12vh" }}
         />
      </Container>
   );
};
