import { Switch } from "@mui/material";
import { useField } from "formik";

interface InputSliderSelectorProps {
   label?: string;
   id: string;
   name: string;
   value?: any;
   required?: boolean;
}

export const InputSliderSelector = (props: InputSliderSelectorProps) => {
   const [field] = useField(props);
   return <Switch checked={field.checked} onChange={field.onChange} id={props.id} name={props.name} />;
};
