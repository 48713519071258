import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ThankYouModalProps {
   open: boolean;
   setOpen: Function;
   company: any;
   reportNumber: string;
   verificationCode: string;
   type: string;
}

export const ThankYouModal = (props: ThankYouModalProps) => {
   const navigate = useNavigate();
   return (
      <Modal open={props.open}>
         <Box
            display={"flex"}
            sx={{
               bgcolor: "transparent",
               justifyContent: "center",
               alignItems: "center",
               width: "100%",
               height: "100%",
            }}
         >
            <Box
               display={"flex"}
               sx={{ flexDirection: "column", alignItems: "center", bgcolor: "white", borderRadius: 2 }}
            >
               <Typography variant="h4" sx={{ p: { xs: 2, lg: 3 }, textAlign: "center", color: "#333333" }}>
                  {`¡Has interpuesto una ${props.type} con éxito!`}
               </Typography>
               <Divider sx={{ bgcolor: props.company.color }} flexItem />
               {/* <Typography variant="body1" sx={{ px: 2, pt: 2, pb: 1, alignSelf: "center" }}>
                  Asegúrate de guardar la siguiente información para futuras consultas.
               </Typography> */}
               <Typography
                  display={"block"}
                  variant="body1"
                  textAlign={"center"}
                  sx={{ py: 1, px: 2, alignSelf: "center" }}
               >
                  {`Con tu número de reporte y código de verificación puedes consultar el estatus de tu ${props.type}.`}
               </Typography>
               <Typography variant="h6" sx={{ px: 2, textAlign: "center" }}>
                  <strong>Número de Reporte:</strong> {props.reportNumber}
               </Typography>
               <Typography variant="h6" sx={{ px: 2, pb: 2, textAlign: "center" }}>
                  <strong>Código de Verificación:</strong> {props.verificationCode}
               </Typography>
               <Typography variant="body2" sx={{ alignSelf: "center" }}>
                  {`Si proporcionaste un correo electrónico, el folio de tu ${props.type} te llegará a través del mismo.`}
               </Typography>
               <Typography variant="body2" sx={{ pb: 1, alignSelf: "center" }}>
                  En caso de que no lo hayas recibido, te recomendamos revisar tu carpeta de correo no deseado o spam.
               </Typography>
               <Divider sx={{ bgcolor: props.company.color, my: 2 }} flexItem />
               <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                  <Typography display={"block"} variant="body2" sx={{ px: 2 }}>
                     {`Tu ${props.type} será atendida y procesada dentro de los próximos días.`}
                  </Typography>
                  <Button
                     variant="contained"
                     sx={{ alignSelf: "flex-end", m: 1, bgcolor: "#B8B8B8" }}
                     onClick={() => {
                        props.setOpen(false);
                        navigate(`/${props.company.subName}`);
                     }}
                  >
                     Aceptar
                  </Button>
               </Box>
            </Box>
         </Box>
      </Modal>
   );
};
