import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { SystemThemeConfig } from "./config/theme.config";
import { CompanyProvider } from "./context/companyContext";
import { SnackBarProvider } from "./context/snackBarContext";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <SystemThemeConfig>
         <CompanyProvider>
            <SnackBarProvider>
               <App />
            </SnackBarProvider>
         </CompanyProvider>
      </SystemThemeConfig>
   </React.StrictMode>
);
