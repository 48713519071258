import * as yup from "yup";
import {
   freeTextRegex,
   numbersOnlyRegex,
   phoneNumberRegex,
   textandNumbersOnlyRegex,
   textOnlyRegex,
} from "./complaintsConst";

export const complainerDetailsSchema = yup.object().shape({
   name: yup.string().required("Campo obligatorio").matches(textOnlyRegex, "Formato inválido"),
   lastName: yup.string().required("Campo obligatorio").matches(textOnlyRegex, "Formato inválido"),
   gender: yup.string().required("Campo obligatorio").matches(textOnlyRegex, "Formato inválido"),
   phoneNumber: yup
      .string()
      .length(10, "Celular debe contener 10 caracteres")
      .required("Campo obligatorio")
      .matches(phoneNumberRegex, "Formato inválido"),
   email: yup.string().email("Formato inválido").required("Campo obligatorio"),
   // address: yup.string().required("Campo obligatorio"),
   // zipCode: yup.string().length(5, "5 caracteres requeridos").required("Campo obligatorio"),
   address: yup.string(),
   zipCode: yup.string().length(5, "5 caracteres requeridos").matches(numbersOnlyRegex, "Ingresar solamente números"),
   country: yup.string().required("Campo obligatorio"),
   state: yup.string().required("Campo obligatorio"),
   city: yup.string().required("Campo obligatorio"),
});

export const witnessInfractorSchema2 = yup.object().shape({
   name: yup.string().notRequired(),
   lastName: yup.string().notRequired(),
   gender: yup.string().required(),
   position: yup.string().notRequired(),
   observations: yup.string(),
});

export const witnessInfractorSchema = yup.object().shape(
   {
      name: yup.string().when("name", (val, schema) => {
         if (val?.length > 0) {
            return yup
               .string()
               .matches(textandNumbersOnlyRegex, "Formato inválido")
               .max(50, "Máximo 50 caracteres")
               .required("Campo obligatorio");
         } else {
            return yup.string().optional();
         }
      }),
      lastName: yup.string().when("name", (val, schema) => {
         if (val?.length > 0) {
            return yup
               .string()
               .matches(textandNumbersOnlyRegex, "Formato inválido")
               .max(50, "Máximo 50 caracteres")
               .required("Campo obligatorio");
         } else {
            return yup.string().optional();
         }
      }),
      gender: yup.string().matches(textOnlyRegex, "Formato inválido").required("Campo obligatorio"),
      position: yup.string().when("position", (val, schema) => {
         if (val?.length > 0) {
            return yup
               .string()
               .matches(freeTextRegex, "Formato inválido")
               .min(3, "Mínimo 3 caracteres")
               .max(50, "Máximo 50 caracteres")
               .required("Campo obligatorio");
         } else {
            return yup.string().optional();
         }
      }),
      observations: yup
         .string()
         .min(5, "Mínimo 5 caracteres")
         .max(500, "Máximo 500 caracteres")
         .notRequired()
         .when(["name", "lastName"], (val, schema) => {
            if (val?.length > 0) {
               return yup.string().optional();
            } else {
               return (
                  yup
                     .string()
                     // .matches(textOnlyRegex, "Formato inválido")
                     .max(500, "Máximo 500 caracteres")
                     .required("Campo obligatorio")
               );
            }
         }),
   },
   [
      ["name", "name"],
      ["lastName", "lastName"],
      ["position", "position"],
   ]
);

export const complaintFormSchema = yup.object().shape({
   pseudonym: yup
      .string()
      .min(3, "Se requiere mínimo 3 caracteres.")
      .max(50, "Máximo de 50 caracteres.")
      .required("Este campo es requerido"),
   followUpEmail: yup.string().email("Correo inválido").required("Este campo es requerido"),
   complaintType: yup.mixed().oneOf(["anonima", "no anonima"]).defined(),
   branch: yup.string(),
   complainerType: yup.string().required("Este campo es requerido"),
   category: yup.string().required("Este campo es requerido"),
   date: yup.date().max(new Date(), "La fecha no puede ser futura").required("Este campo es requerido"),
   body: yup
      .string()
      .min(5, "Se requiere mínimo 5 caracteres.")
      .max(2000, "Máximo 2000 caracteres.")
      .required("Este campo es requerido"),
   witnesses: yup
      .array()
      .of(witnessInfractorSchema2 || null)
      .optional(),
   specificPlace: yup
      .string()
      .min(3, "Se requiere mínimo 3 caracteres.")
      .max(50, "Máximo de 50 caracteres.")
      .required("Este campo es requerido"),
   directlyAffected: yup.boolean().required("Este campo es requerido"),
   present: yup.boolean().required("Este campo es requerido"),
   offenderDetails: yup
      .array()
      .of(witnessInfractorSchema2 || null)
      .optional(),
   checked: yup.boolean().oneOf([true], "Debes aceptar el aviso de privacidad"),
});

export const sugestionFormSchema = yup.object().shape({
   pseudonym: yup
      .string()
      .min(3, "Se requiere mínimo 3 caracteres.")
      .max(50, "Máximo de 50 caracteres.")
      .required("Este campo es requerido"),
   followUpEmail: yup.string().email("Correo inválido").required("Este campo es requerido"),
   complaintType: yup.mixed().oneOf(["anonima", "no anonima"]).defined(),
   branch: yup.string(),
   complainerType: yup.string().required("Este campo es requerido"),
   category: yup.string().required("Este campo es requerido"),
   body: yup
      .string()
      .min(5, "Se requiere mínimo 5 caracteres.")
      .max(2000, "Máximo 2000 caracteres.")
      .required("Este campo es requerido"),
   checked: yup.boolean().oneOf([true], "Debes aceptar el aviso de privacidad"),
});

export const niggleFormSchema = yup.object().shape({
   //quejas
   pseudonym: yup
      .string()
      .min(3, "Se requiere mínimo 3 caracteres.")
      .max(50, "Máximo de 50 caracteres.")
      .required("Este campo es requerido"),
   followUpEmail: yup.string().email("Correo inválido").required("Este campo es requerido"),
   complaintType: yup.mixed().oneOf(["anonima", "no anonima"]).defined(),
   complainerType: yup.string().required("Este campo es requerido"),
   body: yup
      .string()
      .min(5, "Se requiere mínimo 5 caracteres.")
      .max(2000, "Máximo 2000 caracteres.")
      .required("Este campo es requerido"),
   checked: yup.boolean().oneOf([true], "Debes aceptar el aviso de privacidad"),
});

export const complaintStatusTracking = yup.object().shape({
   reportNumber: yup
      .string()
      .min(2, "Ingresa mínimo dos caracteres")
      .max(10, "Ingresa máximo 10 caracteres")
      .matches(numbersOnlyRegex, "Ingresa solamente números")
      .required("Campo obligatorio"),
   verificationCode: yup
      .string()
      .min(2, "Ingresa mínimo dos caracteres")
      .max(10, "Ingresa máximo 10 caracteres")
      .required("Campo obligatorio"),
});

// isCR == isComplaintRelated
export const supportNotificationSchema = yup.object().shape({
   isCR: yup.boolean().required("Este campo es obligatorio"),
   reportNumber: yup.string().when("isCR", { is: true, then: yup.string().required("Este campo es obligatorio") }),
   name: yup.string().notRequired(),
   email: yup.string().email("Correo electrónico inválido").required("Este campo es obligatorio"),
   phoneNumber: yup
      .string()
      .length(10, "Ingresar 10 carácteres")
      .matches(numbersOnlyRegex, "Formato inválido")
      .notRequired(),
   body: yup.string().max(500, "Máximo de 500 caracteres.").required("Este campo es obligatorio"),
   checked: yup.boolean().oneOf([true], "Debes aceptar el aviso de privacidad"),
});
