import { FormControl, FormHelperText, Input, InputLabel, TextField } from "@mui/material";
import { useField } from "formik";

interface TextFieldInterfaceProps {
   label?: string;
   fullWidth?: boolean;
   rows?: number;
   multiline?: boolean;
   variant?: any;
   size?: any;
   id: string;
   name: string;
   placeholder?: string;
   type: string;
   endAdornment?: any;
   disabled?: boolean;
   sx?: object;
   inputProps?: any;
   inputLabelProps?: any;
   children?: React.ReactFragment;
   value?: any;
   required?: boolean;
   helperText?: string;
   maxRows?: number;
   maxLength?: number;
}

export const InputTextField = (props: TextFieldInterfaceProps) => {
   const [field, meta] = useField(props);
   return (
      <>
         {!props.endAdornment ? (
            <TextField
               {...field}
               {...props}
               placeholder={props.placeholder}
               inputProps={{ maxLength: props.maxLength || 50 }}
               error={!!(meta.error && meta.touched)}
               helperText={meta.error && meta.touched ? meta.error : props.helperText}
               sx={{ textOverflow: props.maxRows ? "ellipsis" : "hidden" }}
            />
         ) : (
            <FormControl fullWidth={props.fullWidth} variant="standard" size={props.size} required={props.required}>
               <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
               <Input
                  {...field}
                  inputProps={{ maxLength: props.maxLength || 50 }}
                  // {...props} // With this spread operator, input tag props become invalid.
                  disabled={props.disabled}
                  id={props.id}
                  rows={props.rows}
                  type={props.type}
                  multiline={props.multiline}
                  endAdornment={props.endAdornment}
                  error={!!(meta.error && meta.touched)}
               />
               <FormHelperText error={!!(meta.error && meta.touched)}>
                  {meta.error && meta.touched ? meta.error : null}
               </FormHelperText>
            </FormControl>
         )}
      </>
   );
};
