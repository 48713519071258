import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { CompanyContext } from "../context/companyContext";
import { bgContrast } from "../const/bgContrast";

interface NewComplaintBarProps {
   text: string;
}

export const NewComplaintBar = (props: NewComplaintBarProps) => {
   const { company } = useContext(CompanyContext);

   return (
      <Box
         sx={{
            mx: -3,
            mb: 1,
            py: 1,
            borderTopRightRadius: 50,
            borderBottomRightRadius: 10,
            backgroundColor: company.color,
            minWidth: { xs: "auto", md: "450px" },
            maxWidth: { xs: "375px", md: "auto" },
            boxShadow: 4,
         }}
      >
         <Typography
            variant="h6"
            color={bgContrast(company.color)}
            sx={{ textShadow: "0px 0px 5px #4B4B4B" }}
            textAlign={"center"}
         >
            {props.text}
         </Typography>
      </Box>
   );
};
