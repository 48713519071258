import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { Box, Button, CircularProgress, SxProps, Theme, Typography, Container, Stack } from "@mui/material";
import { CompanyContext } from "../context/companyContext";
import { StatusCheckModal } from "../components/StatusCheckModal";
import { NavBar } from "../components/NavBar";
import { getComplaintByReportNumber } from "../lib/lecosyBackendClient";
import { Form, Formik } from "formik";
import { complaintStatusTracking } from "../const/SchemaValidations";
import { InputTextField } from "../components/Formik Components/InputTextField";
import { SnackBarContext } from "../context/snackBarContext";
import { SnackBarComponent } from "../components/snackBarComponent";
import { bgContrast } from "../const/bgContrast";

export const ComplaintTrackingScreen = () => {
   const { getInfo, company } = useContext(CompanyContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { subName } = useParams();
   const [isLoading, setIsLoading] = useState(false);
   const [statusModalOpen, setStatusModalOpen] = useState(false);
   const [complaintStatus, setComplaintStatus] = useState("");
   const [complaintObs, setComplaintObs] = useState("");

   useEffect(() => {
      getInfo(subName);
      // eslint-disable-next-line
   }, []);

   const formCard: SxProps<Theme> = {
      borderLeft: 6,
      borderColor: company.color,
      borderTopRightRadius: 40,
      borderBottomRightRadius: 20,
      backgroundColor: "white",
      width: { xs: "90%", md: "45%" },
      py: { xs: 2, md: 5 },
      px: { xs: 4, md: 10 },
      mt: 2,
      display: "flex",
      justifyContent: { xs: "center", md: "flex-start" },
      boxShadow: "1px 1px 4px #4B4B4B50",
   };

   const handleComplaintSearch = async (values) => {
      try {
         const response = await getComplaintByReportNumber(
            company.subName,
            values.reportNumber,
            values.verificationCode
         );
         const complaintResponse = response.data;
         setComplaintStatus(complaintResponse.status);
         setComplaintObs(complaintResponse.observations);
         setIsLoading(false);
         setStatusModalOpen(true);
      } catch (error) {
         const errorResponse: AxiosError = error as AxiosError;
         const errorMessage: any = errorResponse.response.data;
         if (errorMessage.message === "denuncia invalida") {
            showSnackBar("Denuncia inexistente o código de verificación incorrecto", true);
            setIsLoading(false);
            return;
         }
         showSnackBar("Error, intente de nuevo", true);
         setIsLoading(false);
      }
   };

   return (
      <Box bgcolor={"#F1F1F1"} height={"100vh"} display="flex" flexDirection={"column"}>
         <NavBar />
         {isLoading ? (
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "#BABABA80",
               }}
            >
               <CircularProgress />
            </Box>
         ) : (
            <></>
         )}
         <Container
            disableGutters
            maxWidth="xl"
            sx={{
               flexGrow: 1,
               backgroundImage:
                  "URL(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fondo-canalDeDenuncias-inicio.jpg)",
               backgroundSize: "cover",
               backgroundPosition: "top center",
            }}
         >
            <Box
               sx={{
                  pt: 2,
                  height: "100%",
                  boxShadow: 4,
               }}
            >
               <Box
                  sx={{
                     alignSelf: "flex-start",
                     backgroundColor: `${company.color}`,
                     borderTopRightRadius: 25,
                     borderBottomRightRadius: 15,
                     p: 2,
                     mb: 2,
                     mt: 2,
                     width: { xs: "90%", md: "45%" },
                  }}
               >
                  <Typography
                     variant="h5"
                     color={bgContrast(company.color)}
                     sx={{ textShadow: "0px 0px 5px #4B4B4B" }}
                     textAlign={"center"}
                  >
                     Seguimiento de denuncia
                  </Typography>
               </Box>
               <Typography variant="body1" sx={{ m: 1, p: { xs: 1, md: 2 } }} textAlign={"center"}>
                  Completa el formulario ingresando el número de reporte y código de verificación de tu denuncia para
                  consultar su estatus actual.
               </Typography>
               <Box sx={formCard}>
                  <Formik
                     initialValues={{
                        reportNumber: "",
                        verificationCode: "",
                     }}
                     onSubmit={handleComplaintSearch}
                     validationSchema={complaintStatusTracking}
                  >
                     <Form>
                        <Stack direction={"column"} spacing={{ xs: 1, md: 4 }}>
                           <Box
                              display={{ xs: "block", sm: "flex" }}
                              sx={{
                                 alignItems: "center",
                                 justifyContent: "space-between",
                              }}
                           >
                              <Typography fontWeight={400} sx={{ mr: 1 }} variant="h6">
                                 Número de Reporte
                              </Typography>
                              <InputTextField id="reportNumber" name="reportNumber" type="text" maxLength={10} />
                           </Box>
                           <Box
                              display={{ xs: "block", sm: "flex" }}
                              sx={{
                                 alignItems: "center",
                                 justifyContent: "space-between",
                              }}
                           >
                              <Typography fontWeight={400} sx={{ mr: 1 }} variant="h6">
                                 Código de Verificación
                              </Typography>
                              <InputTextField
                                 id="verificationCode"
                                 name="verificationCode"
                                 type="password"
                                 maxLength={10}
                              />
                           </Box>
                           <Box display={"flex"} justifyContent={"flex-end"}>
                              <Button
                                 data-cy="LookupBtn"
                                 variant="contained"
                                 type="submit"
                                 sx={{ alignSelf: "flex-end" }}
                                 color="primary"
                              >
                                 Buscar
                              </Button>
                           </Box>
                        </Stack>
                     </Form>
                  </Formik>
               </Box>
            </Box>
            <Box
               width={{ xs: "60%", md: "45%" }}
               height={{ xs: "50px", md: "70px" }}
               bgcolor={"#E4E4E7"}
               position={"absolute"}
               bottom={0}
               right={0}
               sx={{ borderTopLeftRadius: "60px" }}
            />
         </Container>
         <SnackBarComponent />
         <StatusCheckModal
            open={statusModalOpen}
            setOpen={setStatusModalOpen}
            status={complaintStatus}
            observations={complaintObs}
            company={company}
         />
      </Box>
   );
};
