import { Select, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { useField } from "formik";

interface InputSelectFieldInterfaceProps {
   label?: string;
   labelId?: string;
   fullWidth?: boolean;
   multiple?: boolean;
   id: string;
   name: string;
   renderValue?: any;
   children: any;
   variant?: any;
   required?: boolean;
}

export const InputSelectField = (props: InputSelectFieldInterfaceProps) => {
   const [field, meta] = useField(props);
   return (
      <FormControl fullWidth={props.fullWidth} required={props.required}>
         {props.label ? (
            <InputLabel id={props.labelId} sx={{ mx: -1.5 }}>
               {props.label}
            </InputLabel>
         ) : (
            <></>
         )}
         <Select
            MenuProps={{ style: { maxHeight: "370px" } }}
            sx={{ minWidth: { xs: "20vw", md: 200 } }}
            error={meta.error && meta.touched}
            {...props}
            {...field}
         >
            {props.children}
         </Select>
         <FormHelperText error={meta.error && meta.touched}>
            {meta.error && meta.touched ? meta.error : null}
         </FormHelperText>
      </FormControl>
   );
};
