import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NavBar } from "../components/NavBar";
import { CompanyContext } from "../context/companyContext";
import { getUrlForS3File } from "../lib/usersBEClient";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { bgContrast } from "../const/bgContrast";

export const PrivacyPolicyScreen = () => {
   const { getInfo, company } = useContext(CompanyContext);
   const { subName } = useParams();
   useEffect(() => {
      getInfo(subName);
      // eslint-disable-next-line
   }, []);

   const getImageUrl = async () => {
      const response = await getUrlForS3File(company.id, "policies.pdf");
      window.open(response, "_blank", "noopener,noreferrer");
   };

   return (
      <Box>
         <NavBar />
         <Box
            display={"flex"}
            flexDirection={"column"}
            rowGap={4}
            sx={{
               backgroundImage:
                  "URL(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fondo-canalDeDenuncias-inicio.jpg)",
               backgroundSize: "cover",
               backgroundPosition: "center",
            }}
         >
            <Stack
               bgcolor={"#F0F0F0"}
               sx={{
                  borderTopLeftRadius: { md: 40 },
                  borderTopRightRadius: { md: 40 },
                  px: { xs: 3, md: 5 },
                  py: 2,
                  minHeight: "400px",
                  boxShadow: 2,
               }}
               mt={5}
               mx={{ md: 20 }}
            >
               <Typography variant="h6" sx={{ mb: 1, mt: 2, textAlign: "center" }} color={"#162c44"} fontWeight={600}>
                  Aviso de Privacidad
               </Typography>
               <Box sx={{ maxHeight: "400px", overflowY: "auto" }}>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> con domicilio en Calle General José María Yañez número
                     8, Colonia San Benito, C.P. 83190, Hermosillo, Sonora, es responsable de los datos personales que
                     ha recabado o que recabe de sus clientes o de cualquier otra persona física o moral, del uso que se
                     dé a los mismos y de su debida protección. En este contexto, y conforme a lo dispuesto por los
                     artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de los
                     Particulares (así como de los artículos relativos y aplicables de su Reglamento) se hace de su
                     conocimiento, mediante el presente documento, las características y finalidades del tratamiento que
                     se dará a sus datos personales.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     Los datos personales que <strong>LECOSY, S.A.P.I. DE C.V.,</strong> , recabe, almacena y use de sus
                     clientes o prospectos, le son proporcionados de manera voluntaria por cada titular, para efectos de
                     la relación de "prestación de servicios y/o relación comercial". Consecuentemente, dichos datos
                     personales son recopilados única y exclusivamente para: I.- Recopilación de información necesaria
                     del cliente o prospecto para dar inicio a la prestación de nuestros servicios, conocer sus
                     necesidades de productos o servicios y estar en posibilidad de ofrecerle los que más se adecuen a
                     sus preferencias, así como al seguimiento a cualquier relación contractual entre el titular de los
                     datos personales y nuestra empresa, II.- Fines fiscales y/o de facturación, III.- Comercializar
                     software y equipos electrónicos y IV.- Cualquier finalidad análoga o compatible con las anteriores.
                     De igual manera, cualquier persona física puede enviar voluntariamente sus datos (incluyendo
                     curriculum vitae) a <strong>LECOSY, S.A.P.I. DE C.V.,</strong> a efecto de que podamos contactarlo
                     respecto de una posible contratación o para enviarle aquella información que de nuestra empresa
                     resulte de su interés.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     Para las finalidades antes mencionadas, los datos personales recabados y/o a recabarse serán, según
                     corresponda:
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>DATOS DE IDENTIFICACIÓN DE PERSONAS FISICAS:</strong> Nombre, nacionalidad, fecha y lugar
                     de nacimiento, edad, sexo, estado civil, domicilio, ocupación, nombres de familiares, dependientes
                     y beneficiarios, identificación oficial vigente, Registro Federal de Contribuyentes (RFC), Clave
                     Única de Registro de Población (CURP), teléfono particular, teléfono celular y correo electrónico.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>DATOS ACADEMICOS DE PERSONAS FISICAS:</strong> Trayectoria educativa, títulos, cédula
                     profesional, y reconocimientos, entre otros.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>DATOS LABORALES DE PERSONAS FISICAS:</strong> Puesto desempeñado, domicilio de trabajo,
                     teléfono, correo electrónico de trabajo, referencias laborales y personales con Nombre, domicilio,
                     teléfono y relación.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>DATOS GENERALES DE PERSONAS MORALES:</strong> Razón social, nombre del apoderado y/o
                     representante legal, domicilio social, código postal, correo electrónico institucional y teléfono
                     fijo.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>DOCUMENTOS PÚBLICOS EN GENERAL:</strong> Identificaciones oficiales, escrituras públicas de
                     actas constitutivas o asambleas, poderes, entre otras, inscritas ante el Registro Público de la
                     Propiedad y Comercio, oficios de entidades gubernamentales, etcétera.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>DOCUMENTOS PRIVADOS EN GENERAL:</strong> Documentos no inscritos ante el Registro Público
                     de la Propiedad y Comercio, diferentes tipos de actas, contratos, poderes, descripciones escritas o
                     verbales de proyectos para su negocio, empresa o marca, entre otros.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>DATOS PATRIMONIALES:</strong> cuentas bancarias y/o formas de pago.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     Mediante el presente instrumento jurídico, se hace de su conocimiento que{" "}
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> no recaba datos personales sensibles; Entendiéndose como
                     datos personales sensibles lo señalado en el artículo 3, Fracción VI de la Ley que nos ocupa, mismo
                     que es del tenor literal siguiente:
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3, px: { xs: 2, md: 8 } }}>
                     “Aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización
                     indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se
                     consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de
                     salud presente y futuro, información genética, creencias religiosas, filosóficas y morales,
                     afiliación sindical, opiniones políticas, preferencia sexual.”
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     En caso de que consideremos necesario que nos proporcione información y/o datos personales
                     considerados sensibles para efectos de brindarle de manera integral alguno de nuestros servicios,
                     le haremos de su conocimiento dicha situación, por lo cual, para recabar y tratar datos personales
                     del titular usted nos deberá otorgar su consentimiento expreso y por escrito de su parte, a través
                     de nuestro formato de consentimiento de tratamiento de datos personales autorizado por{" "}
                     <strong>LECOSY, S.A.P.I. DE C.V.</strong>
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> y todo su personal reconocemos la importancia de un
                     adecuado tratamiento para sus datos personales y, como consecuencia de ello, se han implementado
                     diversos controles y medidas de seguridad tales como Políticas de Privacidad, Guía para el borrado
                     seguro de datos personales, implementación del sistema de gestión de seguridad de datos personales,
                     que permiten que dichos datos personales conserven su carácter confidencial y previenen tanto un
                     acceso o uso no autorizado como posibles daños, pérdidas o alteraciones a los mismos.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     Los datos personales recabados por <strong>LECOSY, S.A.P.I. DE C.V.,</strong> no se comparten ni se
                     transfieren con terceros a excepción de aquellos que fueran requeridos por autoridades federales o
                     locales, aquellos respecto de los cuales usted hubiera dado su consentimiento en forma previa o
                     aquellos que usted nos haya proporcionado para que sean presentados o transferidos a terceros
                     determinados como parte de un trámite o un asunto legal. Dichos datos personales son y/o serán
                     utilizados solamente por el personal autorizado al efecto y en relación con las finalidades antes
                     mencionadas (atendiendo en todo momento a los principios de protección de datos establecidos por
                     Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento).
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     No obstante, si usted tiene cualquier duda o inquietud acerca de nuestras prácticas de privacidad
                     le pedimos se ponga en contacto con nosotros a través de la dirección electrónica
                     administracion@lecosy.com.mx o bien comunicarse al departamento de tratamiento de datos personales
                     con número telefónico <strong>(662) 151 69 43,</strong> quién es el responsable de guardar y
                     custodiar la información de datos personales. De igual forma, si usted no desea recibir cualquier
                     aviso le solicitamos que lo haga de nuestro conocimiento.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     Usted podrá revocar el consentimiento que nos haya otorgado y que fuere necesario para el
                     tratamiento de sus datos personales a fin de que los mismos se dejen de usar. Para dichos efectos,
                     deberá enviar su petición a la dirección electrónica señalada en el párrafo anterior o bien
                     presentarla por escrito en el domicilio de la empresa o por llamada al número{" "}
                     <strong>(662) 151 69 43,</strong> en atención al departamento de tratamiento de datos personales,
                     quien es el responsable de guardar y custodiar la información de datos personales y en un plazo
                     máximo de 20 días hábiles a partir de la recepción de la misma dará respuesta a esta solicitud.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     De igual forma, usted tiene derecho de acceder a los datos personales que{" "}
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> posea en relación a su persona y a los detalles del
                     tratamiento de los mismos, así como también tiene derecho a actualizarlos o rectificarlos de ser
                     éstos inexactos o incorrectos, a instruirnos su cancelación (cuando se hayan cumplido las
                     finalidades para las cuales fueron recabados) y a oponerse al tratamiento de los mismos para fines
                     específicos o por existir causa legítima para ello. El ejercicio de los derechos de acceso,
                     rectificación, cancelación y oposición antes mencionados podrá hacerse valer mediante escrito que
                     presente en las oficinas de <strong>LECOSY, S.A.P.I. DE C.V.,</strong> siempre y cuando cumpla con
                     la información y anexos mínimos descritos por la Ley Federal de Protección de Datos Personales en
                     Posesión de los Particulares y su Reglamento (1. El nombre del titular y domicilio u otro medio
                     para comunicarle la respuesta a su solicitud, 2. Copia de un documento que acredite su identidad,
                     3. La descripción clara y precisa de los datos personales sobre los que busca ejercer alguno de
                     estos derechos y 4. Descripción clara y precisa de su solicitud), teniendo la empresa el compromiso
                     de dar contestación dentro de los plazos establecidos por dichos ordenamientos.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     Habiéndose puesto a su disposición el presente aviso de privacidad y usted no manifieste su derecho
                     de oposición al respecto, se considerará por Ley que usted le otorga a{" "}
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> consentimiento tácito, para que podamos llevar a cabo
                     recopilación, tratamiento y generación de datos personales del titular bajo los lineamientos
                     establecidos en el presente instrumento jurídico y según lo establecido por los artículos 3
                     fracción IV, V, 7, 8, 12, 17 y 37 fracción V de la Ley; asimismo, cabe hacer mención que sin la
                     necesidad de su consentimiento podremos allegarnos y/o hacer tratamiento de sus datos personales
                     que figuren en fuentes del dominio público según lo dispuesto en el artículo 10 fracción II de la
                     Ley; por otra parte, de las obligaciones derivadas de nuestra relación jurídica podremos recabar,
                     tratar (o en su caso generar) sus datos personales financieros y/o patrimoniales, sin necesidad de
                     que usted nos otorgue consentimiento expreso según lo dispuesto en el artículo 8 cuarto párrafo en
                     relación con el artículo 10 fracción IV de la Ley por estar ligado a los servicios contratados con
                     <strong>LECOSY, S.A.P.I. DE C.V;</strong> Al no oponerse al presente aviso de privacidad usted
                     declara y acepta que cuenta con el consentimiento propio y de aquellos terceros de los cuales usted
                     proporcione sus datos en el entendido de que dichos datos se regirán por los lineamientos legales
                     del presente instrumento jurídico y la política de privacidad de la empresa.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> se reserva el derecho de actualizar y/o modificar los
                     términos del presente aviso de privacidad en cualquier momento, en cuyo caso, comunicará dicha
                     situación mediante un correo electrónico a la dirección que usted nos haya proporcionado.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     <strong>LECOSY, S.A.P.I. DE C.V.</strong>, se reserva el derecho de actualizar y/o modificar los
                     términos del presente aviso de privacidad en cualquier momento, en cuyo caso, comunicará dicha
                     situación mediante un correo electrónico a la dirección que usted nos haya proporcionado.
                  </Typography>
                  <Typography variant="body1" textAlign={"justify"} sx={{ mb: 3 }}>
                     Finalmente, le solicitamos a fin de cumplir con el principio de calidad en el tratamiento de datos
                     personales que, de ser necesaria una actualización o una corrección en sus datos personales, se
                     ponga en contacto con nosotros para así permitirnos contar con una base de datos exacta, completa,
                     correcta y actualizada.
                  </Typography>
               </Box>
            </Stack>
            <Box
               alignSelf={"flex-end"}
               display="flex"
               alignItems="center"
               sx={{
                  bgcolor: company.color,
                  py: 1,
                  pl: { xs: 3, md: 10 },
                  borderTopLeftRadius: "39.35px",
                  borderBottomLeftRadius: "15.74px",
               }}
               color={bgContrast(company.color)}
               width={{ xs: "100%", md: "700px" }}
            >
               <IconButton onClick={getImageUrl} sx={{ mx: 2, color: "white" }}>
                  <FindInPageIcon />
               </IconButton>
               <Typography>Consulte el aviso de privacidad de&nbsp;{company.name}</Typography>
            </Box>
         </Box>
      </Box>
   );
};
