import { useContext, useMemo, useState } from "react";
import {
   AppBar,
   Box,
   Button,
   CssBaseline,
   Divider,
   Drawer,
   IconButton,
   List,
   ListItem,
   ListItemButton,
   ListItemText,
   Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { CompanyContext } from "../context/companyContext";
import { useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 240;

export const NavBar = () => {
   const { company } = useContext(CompanyContext);
   const location = useLocation();
   const navigate = useNavigate();
   const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
   const [drawerToggle, setDrawerToggle] = useState(false);

   const navItems = useMemo(() => {
      const items = ["Seguimiento de Denuncia", "Aviso de Privacidad"];
      if (location.pathname.split("/").length > 2) items.unshift("Inicio");
      return items;
   }, [location.pathname]);

   const navItemsObj = useMemo(() => {
      const subName = location.pathname.split("/");
      const items = [
         { name: "Seguimiento de Denuncia", path: `/${subName[1]}/seguimiento` },
         { name: "Aviso de Privacidad", path: `/${subName[1]}/aviso-de-privacidad` },
      ];
      if (location.pathname.split("/").length > 2) items.unshift({ name: "Inicio", path: `/${subName[1]}` });
      return items;
   }, [location.pathname]);

   const handleMobileOpen = () => {
      setMobileDrawerOpen(!mobileDrawerOpen);
   };
   const handleDrawerToggle = () => {
      setDrawerToggle(!drawerToggle);
   };

   const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
         {company.logo && (
            <Box
               onClick={() => navigate(`/${company.subName}`)}
               sx={{
                  maxHeight: 70,
                  maxWidth: 200,
                  marginTop: 8,
                  marginBottom: 8,
                  backgroundImage: "URL.createObjectURL(company.logo)",
                  ":hover": { cursor: "pointer" },
               }}
            />
         )}
         <Divider />
         <List>
            {navItems.map((item) => (
               <ListItem key={item} disablePadding>
                  <ListItemButton
                     sx={{ textAlign: "left" }}
                     onClick={() => {
                        if (item === "Inicio") {
                           navigate(`/${company.subName}`);
                        }
                        if (item === "Seguimiento de Denuncia") {
                           navigate(`/${company.subName}/seguimiento`);
                        }
                        if (item === "Aviso de Privacidad") {
                           navigate(`/${company.subName}/aviso-de-privacidad`);
                        }
                     }}
                  >
                     <ListItemText primary={item} />
                  </ListItemButton>
               </ListItem>
            ))}
         </List>
         {company.subName.toLowerCase() !== "lecosy" ? (
            <Box
               component={"img"}
               src={"https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png"}
               sx={{ width: 150 }}
            />
         ) : (
            <></>
         )}
      </Box>
   );

   return (
      <>
         <CssBaseline />
         <AppBar component="nav" color="default" sx={{ py: 1, zIndex: 1 }} position={"relative"}>
            <Toolbar>
               <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleMobileOpen}
                  sx={{ mr: 2, display: { sm: "none" } }}
               >
                  <MenuIcon />
               </IconButton>
               {company.logo ? (
                  <Box
                     component={"img"}
                     src={URL.createObjectURL(company.logo)}
                     alt={`${company.name} logo`}
                     loading="lazy"
                     sx={{ maxWidth: 200, maxHeight: 60, display: mobileDrawerOpen ? "none" : "block" }}
                     onClick={() => navigate(`/${company.subName}`)}
                  />
               ) : (
                  <></>
               )}
               <Box
                  sx={{
                     display: { xs: "none", sm: "flex" },
                     flex: 1,
                     justifyContent: "flex-end",
                  }}
               >
                  {navItemsObj.map((element) => (
                     <Button key={element.name} onClick={() => navigate(element.path)}>
                        {element.name}
                     </Button>
                  ))}
               </Box>
               {company.subName.toLowerCase() !== "lecosy" ? (
                  <Box
                     component={"img"}
                     src={"https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png"}
                     sx={{ display: { xs: "none", sm: "block" }, width: 150, ml: 5 }}
                  />
               ) : (
                  <></>
               )}
            </Toolbar>
         </AppBar>
         <Box component="nav">
            <Drawer
               variant="temporary"
               open={mobileDrawerOpen}
               onClose={handleMobileOpen}
               ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
               }}
               sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
               }}
            >
               {drawer}
            </Drawer>
         </Box>
      </>
   );
};
