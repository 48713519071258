import { Box, Typography } from "@mui/material";

export const NotFoundScreen = () => {
   return (
      <Box
         display="flex"
         sx={{
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
         }}
      >
         <Box sx={{ width: "100%", p: 4, bgcolor: "#2d4357" }}>
            <Box
               component={"img"}
               src={"https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy-blanco.png"}
               width={200}
            />
         </Box>
         <Box display={"flex"} sx={{ width: "100%", height: "100%", justifyContent: "center" }}>
            <Box display={"flex"} sx={{ m: 4, flexDirection: "column", alignItems: "center" }}>
               <Typography fontWeight={700} fontSize={200} color="#2d4357" variant="h1">
                  404
               </Typography>
               <Typography color="#2d4357" variant="h4" sx={{ textAlign: "center" }}>
                  ¡No hemos encontrado esa empresa en nuestro canal de denuncias!
               </Typography>
               <Typography color="#2d4357" variant="h5" sx={{ mt: 2, textAlign: "center" }}>
                  Verifique que el nombre este bien escrito
               </Typography>
            </Box>
         </Box>
      </Box>
   );
};
