import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CenteredLecosyScreen } from "./screens/CenteredLecosyScreen";
import { ComplaintTrackingScreen } from "./screens/ComplaintTrackingScreen";
import { MainScreen } from "./screens/MainScreen";
import { NewComplaintScreen } from "./screens/NewComplaintScreen";
import { NotFoundScreen } from "./screens/NotFoundScreen";
import { PrivacyPolicyScreen } from "./screens/PrivacyPolicyScreen";

export default function App() {
   return (
      <BrowserRouter>
         <Routes>
            <Route path="/" element={<CenteredLecosyScreen />} />
            <Route path=":subName" element={<MainScreen />} />
            <Route path=":subName/nueva/:type" element={<NewComplaintScreen />} />
            <Route path=":subName/seguimiento" element={<ComplaintTrackingScreen />} />
            <Route path=":subName/aviso-de-privacidad" element={<PrivacyPolicyScreen />} />
            <Route path="404" element={<NotFoundScreen />} />
         </Routes>
      </BrowserRouter>
   );
}
