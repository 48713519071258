import axios, { AxiosRequestConfig } from "axios";
import { ComplainerDetailsType, ComplaintInvolvedType } from "../types/BaseTypes";

const base_url =
   window.location.hostname === "test.denuncias.lecosy.com.mx" ||
   window.location.hostname === "www.test.denuncias.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx"
      : "http://localhost:3030";

const host = base_url;
const BASE_ENDPOINT = "/api";
const MASTER_ENDPOINT = "/master";
const CLIENT_ENDPOINT = "/client";
const COMPLAINT_ENDPOINT = "/complaints";
const DENOUNCE_CHANNEL_URL = "/cd";

export const createComplaint = async (
   type: string,
   subName: string,
   intern: boolean,
   followUpEmail: string,
   complaintType: string,
   complainerType: string,
   body: string,
   category?: string,
   date?: string,
   specificPlace?: string,
   present?: boolean,
   directlyAffected?: boolean,
   company?: string,
   companyBranch?: string,
   pseudonym?: string,
   complainerDetails?: ComplainerDetailsType,
   witnesses?: ComplaintInvolvedType[],
   evidence?: string[],
   offenderDetails?: ComplaintInvolvedType[]
) => {
   const rbody = {
      company: company,
      companyBranch: companyBranch,
      subName: subName,
      intern: intern,
      pseudonym: pseudonym,
      followUpEmail: followUpEmail,
      complaintType: complaintType,
      complainerDetails: complainerDetails,
      complainerType: complainerType,
      category: category,
      date: date,
      body: body,
      witnesses: witnesses,
      specificPlace: specificPlace,
      present: present,
      directlyAffected: directlyAffected,
      evidence: evidence,
      offenderDetails: offenderDetails,
      type: type,
   };
   Object.keys(rbody).forEach((key) =>
      rbody[key] === undefined || rbody[key] === null || rbody[key] === "" ? delete rbody[key] : {}
   );
   return axios.post(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINT_ENDPOINT}/new-complaint`,
      rbody
   );
};

export const complaintUploadEvidence = async (signedURL, file) => {
   const config = {
      headers: {
         "Content-Type": `${file.type}`,
      },
   };
   await axios.put(signedURL, file, config);
};

export const getComplaintByReportNumber = async (subName: string, reportNumber: string, verificationCode: string) => {
   const config: AxiosRequestConfig = {
      headers: {
         rn: reportNumber,
         vc: verificationCode,
         sn: subName,
      },
   };
   return axios.get(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINT_ENDPOINT}/tracking`,
      config
   );
};

export const verifyReportNumber = async (reportNumber: string) => {
   const { data } = await axios.get(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINT_ENDPOINT}/${reportNumber}/verify`
   );
   return data;
};
